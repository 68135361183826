.header{
    height:20%;
    width: 100%;
    padding: 0 2%;
    background: #1d1160;
    background: linear-gradient(180deg, #1d1160 0%, rgba(0,0,0,0) 100%);
    position: relative;
    margin: 0 auto;
    margin-bottom: 30px;
}
nav{
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}
.header h1{
    width: 140px;
}
.nav_link{
    margin: auto 0;
    padding: 28px 0;
}
.nav_link li{
    display: inline-block;
    margin: 0 15px;
}
.nav_link li a{
    text-decoration: none;
    color:#fff;
    padding: 5px 0;
    position: relative;
}
.nav_link li a::after{
    content: '';
    background-color: var(--orange);
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.5s;
}
.nav_link li a:hover::after{
    width: 100%;
}
.nav_link li button{
    text-decoration: none;
    color:#fff;
    padding: 5px 0;
    position: relative;
    background: none;
    border: none;
}
.nav_link li button::after{
    content: '';
    background-color: var(--orange);
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.5s;
}
.nav_link li button:hover::after{
    width: 100%;
}
.nav_link .dropdown li{
    margin: 0 15px;
    display: inline-block;
}
.nav_link .dropdown li a::after{
    content: '';
    background-color: var(--orange);
    width: 0;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: width 0.5s;
}
.nav_link .dropdown li a:hover::after{
    width: 100%;
}
.dropdown:hover .dropdown-content{
    opacity: 100;
    display: block;
}
/*#HeAnimes li a:hover .dropdown-content{
    display: block;
}*/
/* .content{
    margin: 0 10px;
} */
.newanime{
    width: 100%;
    padding: 20px 20px;
    height: 500px;
    background: #21024d;
    background: linear-gradient(180deg,rgba(19,0,41,1) 0%, rgb(28 17 86) 5%, rgba(29,0,41,1) 100%);
    margin: 40px auto;

}
.newanime .newanimein{
    display: block;
    /* background-color: var(--dark_purple3); */
    width: 100%;
    height: 400px;
    padding: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
}
.newanimein::-webkit-scrollbar{
    height: 10px;
}.newanimein::-webkit-scrollbar-track{
    background: var(--blue) !important;
    background: linear-gradient(90deg,var(--blue) 0%, var(--orange) 50%, var(--blue) 100%);
}
.newanime .newanimein .newanime-t{
    min-width: 229px;
    border: rgb(189, 189, 189) 1px solid;
    background: #14022e;
    float: left;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 0 !important;
    height: 99%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: all 1s;
    border-radius: 10px;
}
.newanime-t:hover .highlight-hover{
    background-color: rgba(20,0,37,.5);
}
.newanime-t:hover .highlight-body{
    display: block;
}
.highlight-img{
    height: 100%;
}
.highlight-hover{
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all .6s;
    z-index: 2;
    background-color: rgba(20, 0, 37, 0);
}
.highlight-body{
    display: none;
    padding: 15px;
    top:0;
    z-index: 3;
    position: absolute;
    height: 100%;
    width: 100%;
    transition: all .6s;
}
.highlight-time span{
    font-size: 0.9rem  !important;
}
.highlight-genres span{
    border: 1px solid white;
    border-radius: 25px;
    display: inline-block;
    padding: 0 9px;
    margin-left: 5px;
    font-size: .7rem !important;
}
.highlight-genres span:first-child span:last-child{
    margin-left: 0px;
}

.highlight-title{
    position: absolute;
    bottom: calc(80%/2 + 5px - 40px);
}
.highlight-desc{
    position: absolute;
    bottom: 15px;
    font-size: 15px !important;
    line-height: normal !important;
    width: calc((100% - 20px));
}
.highlight-desc span{
    font-size:.7rem !important;
}
.newanime-t img{
    height: 100%;
    object-fit: cover;
}
.newanime .newanimein .rightbut{
    display: inline-block;
    height: 80px;
    width: 40px;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: absolute;
    top: 50%;
    right: -40px;
    margin-top: -40px;
}
.newanime .newanimein button{
    border: none;
}
.newanime .newanimetitle{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 30px;
}
.newMoreBut{
    cursor: pointer;
    background: var(--light_blue);
    border: #3c3a83;
    border-radius: 6px;
    padding: 15px;
    text-decoration: none !important;
    transition: background 0.2s linear;
}
.newMoreBut:hover{
    background: var(--light_blue2);
}
.main-footer{
    /* position: fixed; */
    /* bottom: 0; */
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(0deg, var(--dark_purple) 0%, rgba(22,1,45,1) 62%, var(--body-purple) 100%);
    margin-top: auto;
    padding: 20px 60px;
}
.main-footer div{
    width: 200px;
    padding: 10px;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    font-size: 12px;
}
.main-footer a{
    text-decoration: none;
}
.main-footer a span:hover{
    color: rgb(189, 189, 189);
}
.main-footer p{
    font-size: 20px;
}
.dropdown{
    display: inline-block;
}
.dropdown-content{
    display: block;
    position: absolute;
    z-index: 1;
    opacity: 0;
    background-color:var(--dark_purple3);
    margin-top: 1px;
    border: var(--border_purple) 1px solid;
    border-radius: 5px;
    transition: all 0.5s;
}
.dropdown-content a{
    float: none;
    padding: 12px 16px;
    text-decoration: none !important;
    display: block;
    text-align: left;
    color: white;
}
.dropdown-content a:first-child{
    border-radius: 5px;
}
.dropdown-content a:last-child{
    border-radius: 5px;
}
.dropdown-content a:hover{
    background-color: var(--dark_purple2);
}
