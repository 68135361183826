.episodes{
    width: 100%;
    padding: 1em;
}
.episodes-link{
    aspect-ratio: 16/9;
    border: 1px solid #fff;
    width: calc(25% - 10px);
    display: inline-block;
    margin: 10px 5px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}
.ep-hover {
    height: 100%;
    position: absolute;
    transition: all .6s;
    width: 100%;
}
.ep-body{
    height: 100%;
    position: absolute;
    transition: all .6s;
    width: 100%;
    padding: 15px;
    top: 0;
    z-index: 3;
    color: white;
}
.episodes-link:hover .ep-hover{
    background-color: rgba(20,0,37,.5);
}
.ep-img{
    height: 100%;
}
.ep-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.episodes-link:hover .ep-body{
    display: block;
}
.ep-body{
    display: none;
    padding: 15px;
    top: 0;
    z-index: 3;
}
.ep-title{
    bottom: 15px;
    font-size: 15px!important;
    line-height: normal!important;
    position: absolute;
    width: calc(100% - 20px);
}
.ep-animetitle{
    bottom: calc(43% - 35px);
    position: absolute;
}
.ep-animeseason{
    bottom: calc(30% - 20px);
    font-size: 13px!important;
    position: absolute;
}
.ep-time{
    width: 17%;
    display: flex;
    justify-content: space-between;
}
.ep-res{
    display: flex;
    justify-content: space-between;
    width: 17%;
    float: right;
}
.ep-res-in{
    padding: 0.3em;
    width: 100%;
    height: 1.8em;
    background-color: var(--gray);
    border-radius: 10px;
    justify-content: center;
    display: inherit;
}
