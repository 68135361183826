h1, h2{
    font-size: 18px;
}
.selectN{
    color:black;
}
.selectN option{
    color: black;
}
.contentL{
    width: 55%;
    min-height: 100px;
    display: inline-block;
}
.contentR{
    /* width: 45%; */
    float: right;
    display: flex;
    padding: 10px;
    /* height: calc(min-content + 10px); */
    flex-direction: column;
    align-items: center;
}
.contentR * {
    float: right;
}
.aniSNota{
    border: 1px white solid;
    border-radius: 5px;
    background: none;
    cursor: pointer;
}
#aniNom{
    margin-bottom: 0.3em;
}
#aniNota option{
    color:black !important
}
#aniNota{
    width: 206px;
    color: black !important;
}
.im{
    height: 25em;
    width: fit-content;
    display: inline;
    float: right;
}
.not{
    margin: 2em;
    max-width: 9.5em;
    display: flex;
    flex-direction: column;
    /* height: 4em; */
}
/* .not select{
    margin: 10px;
} */
.im img{
    height: 100%;
    border-radius: 7px;
    box-shadow: 1em 1em 1em rgba(9, 0, 14, 0.781)
}
.contentL h2{
    font-size: 2.2rem !important;
}
.dura{
    display: inline;
}
.contentL .dura span{
    font-size: 0.7rem !important;
}
.aniLik{
    background-color: var(--gray);
    border-radius: 50px;
    padding-top: 3px;
    padding-left: 6px;
    width: 35px;
    height: 35px;
    margin-left: 10px;
    border-color: white;
    border-style: outset;
    transition: all .2s;
    outline: none !important;
}
.aniLik:hover{
    background-color: var(--gray-dark);
}
.aniLikSel{
    background-color: var(--red);
    border-radius: 50px;
    padding-top: 3px;
    padding-left: 6px;
    width: 35px;
    margin-left: 10px;
    border-color: rgb(131, 0, 0);
    border-style: outset;
    transition: all .2s;
    outline: none !important;
}
.aniLikSel:hover{
    background-color: red;
}
#aniDesc{
    font-size: .8rem !important;
    text-justify:inter-cluster;
}
.aniG{
    margin-bottom: 40px;
}
.aniGenIn{
    display: inline-block;
    min-width: 20px;
    width: auto;
    padding: 5px;
    border: white 1px solid;
    border-radius: 10px;
    margin: 2px 10px;
    transition: .3s;
    cursor: pointer;
}
.aniGenIn:first-child{
    margin-left: 0;
}
.aniGenIn:hover{
    background-color: var(--light_blue);
    box-shadow: 0 0 30px 0 var(--light_blue);
}
.eps{
    background-color: var(--gray-dark);
    width: 100%;
    height: max-content;
    padding: 1em;
    /* aspect-ratio: 2/1; */
    /* display: flex; */
    border-radius: 10px;
}
.eps .ep{
    border: var(--gray) 1px solid;
    border-radius: .62em;
    width: 100%;
    padding: .7em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .2em 0;
}
.eps div .selected{
    color: var(--gray);
    background-color: var(--dark_purple);
}
.eps div button{
    background-color: var(--purple);
    border: none;
    border-radius: 1em;
    padding: .6em;
    transition: all .3s;
    margin: 0px 2px;
}
.eps div button:hover{
    background-color: var(--dark_purple);
}
.ep a button i{
    color:#fff;
    transition: all .2s;
}
.ep a button:hover i{
    color:#43005f;
}
.rating span{
    font-size: 1.875em;
}
.seasons{
    background-color: var(--gray-dark);
    display: flex;
    aspect-ratio: 20/1;
    margin: 1em 0;
    border-radius: .62;
    width: 100%;
    padding: 1em;
}
.seasons select {
    width: 100%;
    background-color: #333;
    border-radius: 5px;
}
.personagens{
    padding: 20px 20px;
    margin: 31px 0;
    border: 1px white solid;
    border-radius: 10px;
}
.persoBut{
    cursor: pointer;
    background: var(--light_blue);
    border: #3c3a83;
    border-radius: 6px;
    padding: 15px;
    text-decoration: none !important;
    transition: all .2s linear;
}
.persoBut:hover{
    background: var(--light_blue2);
}
.addAnimeList{
    margin: 2em;
    background: none;
    border: 1px white solid;
    border-radius: 5px;
    transition: all 0.2s linear;
}
.addAnimeList:hover{
    background: var(--dark_purple);
}
.popup-overlay{
    background:rgba(9, 0, 14, 0.5)
}
.edit-list-content{
    padding: 5em;
    background: var(--light_blue2);
    border-radius: 5px;
    width: 50em;
}
.close-popup{
    background: none;
    border: none;
    /* margin-left: auto; */
    width: 30px;
    float: right;
}
.close-popup:hover i{
    color: #9e9e9e;
}
/* .edit-list-content{
    display: flex;
    flex-direction: column;
    margin: 2em 5em;
    justify-content: space-around;
    height: 30em;
    overflow: auto;
} */
.edit-list-content .edit-list{
    display: flex;
    flex-direction: column;
    height: 30em;
    justify-content: space-around;
    margin: 2em;
    overflow: auto;
}
.edit-list .status select,
.edit-list .status option{
    color: black;
}
.edit-list .update-button{
    width: 48%;
    background: none;
    border: 1px solid white;
    border-radius: 5px;
}
.update-button:hover{
    background: var(--dark_purple);
}
/*.comments{*/
/*    margin: 0 5em;*/
/*}*/
