.login{
    background: var(--light_blue2);
    /* background: radial-gradient(circle, rgba(104,5,242,1) 0%, rgba(9,9,121,1) 76%, rgba(33,2,77,1) 100%); */
    margin: auto;
    height: 33em;
    min-width: 30%;
    border-radius: 10px;
}
/* .login p{
    margin: 1em auto;
    width: max-content;
}
.login span{
    margin: 1em auto;
} */
.login div{
    width: max-content;
    margin: 3em auto;
}
.login .logBut{
    background: none;
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 1em;
}
.logBut:disabled{
    background: var(--dark);
    border: 1px solid #797979;
    color: #797979;
    cursor: progress;
}
.logBut:disabled i{
    color: #797979;
}
.div-flex{
    display: flex;
    flex-direction: column;
}