.download-main{
    margin: 1em 20em;
    background-color: var(--dark_purple);
    padding: 10em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.main-download-title{
    margin: auto;
    float: left;
    display: flex;
    flex-direction: column;
}
.main-download-content{
    margin: auto;
    float: right;
}
.download-button{
    border: none;
    margin: 10px;
    border-radius: 5px;
}
.download-button[name="1080"]{
    background-color: var(--red);
}
.download-button[name="720"]{
    background-color: var(--lightpink);
}
.download-button[name="480"]{
    background-color: var(--light_blue2);
}
.download-img{
    max-width: 10em;
    margin: 30px 0;
}
