.main-loading{
    height: 100%;
    display: flex;
    min-height: 524px;
    width: 95%;
    padding: 20px 60px;
    justify-content: center;
}
.main-loading div{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.main-loading div svg{
    color: white;
    margin:auto;
    font-size: 1.5em;
}
.main-loading div h1{
    margin: auto;
}
